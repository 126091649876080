import React, { Component } from 'react';
import NSNHeader from "../components/header";
import '../style/results_entry.css';
import '../style/score.css';
import { getComps, getCompCourses, getCourseObstacles, postScorecard, postStandings, postUpdatedScorecard, getCompAthletes } from '../api/api';
import { convertObstacles, calculateScore, resetObstacleArr, rankFunction, checkActiveScorecard } from '../functions/controllers';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Column, Row } from 'simple-flexbox';
import Stopwatch from '../components/StopWatch';
import timeFormat, { zeroPad } from '../functions/timeFormat';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faCircle, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';

library.add(faArrowAltCircleLeft, faCircle, faTimes, faCheck);

class ResultsEntry extends Component {

    constructor(props) {
        super(props)
        this.maleRef = React.createRef()   // Create a ref object 
        this.femaleRef = React.createRef()   // Create a ref object 
    }

    state = {
        eventsArr: [{ qualifier_id: '', qualifier_name: '' }],
        event: "",
        comp_type: '',
        courses: [],
        athletes: [],
        obstacles: [],
        courseIndex: "",
        showEventSelect: false,
        showCourseSelect: false,
        showBoardSelect: false,
        showRunOrder: false,
        showLeaderboard: false,
        showScorecard: false,
        currentAthlete: '',
        currentAthleteFirst: '',
        currentAthleteLast: '',
        currentAthleteGender: '',
        currentResult: '',
        currentAthleteIndex: '',
        tiebreakerOb: "",
        tieBreakMin: '',
        tieBreakSec: '',
        tieBreakMs: '',
        openDialog: false,
        runOrder: [],
        leaderboard: [],
        rankArr: [],
        openFinalDialog: false,
        completedCount: 0,
        errorDialog: false,
        errorMsg: '',
        existingScorecard: false,
        obstacleIndex: 0,
        editTime: false,
        editTimeMin: '',
        editTimeSec: '',
        editTimeMS: '',
        editObInd: 0,
        toggleMobileLeaderboard: false,
        live_clock: false,
        result_table: '',
        confirmRemove: false,
        removeInd: 0,
        totalFails: 0,
        firstFail: 0,
        resultIDString: '',
        errType: '',
        addFailArr: [],
        ws: null
    };

    timeout = 250; // Initial timeout duration as a class variable
    // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    // Good to go 
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleCheck = name => event => {
        var result = this.state[name] == "true" ? "false" : "true";
        this.props.history.push({
            pathname: 'results_entry',
            search: '?' + new URLSearchParams({ live_clock: result }).toString()
        });
        this.setState({
            [name]: result,
        });
    };
    // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

    // scrollToMyRef = (ref) => document.getElementById('pageContainer').scrollTo(0, this[ref].current.offsetTop - 100)

    // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    // WebSocket Connection
    connect = (compId) => {
        var ws = new WebSocket("wss://7o5i09cjfa.execute-api.us-east-2.amazonaws.com/production?comp_id=" + compId);
        let that = this; // cache the this
        var connectInterval;

        // websocket onopen event listener
        ws.onopen = () => {
               console.log("connected websocket main component");
            this.setState({ ws: ws });
               //var wsObj = {uid: "0", clock_status: "warming", event_id: this.props.event_id, athlete: this.props.athlete_name, gender: this.props.gender, obstacle_result: '', time: "00:00.00", current_obstacle: this.props.obstacleInd, total_obstacles: this.props.obstacleCnt}
               //this.sendMessage({"action": "clockUpdate", "message": JSON.stringify(wsObj) }); //send to websocket if live
            that.timeout = 250; // reset timer to 250 on open of websocket connection 
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        };


        ws.onmessage = evt => {
            // listen to data sent from the websocket server
            const message = evt.data;

            // console.log(evt);
        }

        // websocket onclose event listener
        ws.onclose = e => {
            if (e.type !== 'close') {
                console.log(
                    `Socket is closed. Reconnect will be attempted in ${Math.min(
                        10000 / 1000,
                        (that.timeout + that.timeout) / 1000
                    )} second.`,
                    e.reason
                );

                that.timeout = that.timeout + that.timeout; //increment retry interval
                connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
            }
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );

            ws.close();
        };
    };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check = () => {
        const { ws } = this.state;
        if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
    };

    sendMessage = (data) => {
        this.check();
        const { ws } = this.state // websocket instance passed as props to the child component.
        try {
            console.log('Results Entry Send Message...')
            ws.send(JSON.stringify(data)) //send data to the server
        } catch (error) {
            console.log(error) // catch error
            // this.connect();
            // setTimeout(() => {
            //     ws.send(JSON.stringify(data))
            //   }, 5000);
        }
    }

    closeSocket = () => {
        const { ws } = this.state // websocket instance passed as props to the child component.
        // console.log(data);
        try {
            ws.close() //send data to the server
        } catch (error) {
            //   console.log(error) // catch error
        }
    }
    // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

    toggleMobileLB() {
        var divState;
        if (this.state.toggleMobileLeaderboard == true) {
            divState = false;
        } else {
            divState = true;
        }
        this.setState({ toggleMobileLeaderboard: divState });
    }


    setEvent(val, type) {
        this.setState({ event: val, comp_type: type, showEventSelect: false, showCourseSelect: true });
        getCompCourses(val).then(resp => {
            console.log(resp);
            if (this.state.live_clock == true || this.state.live_clock == 'true') {
                this.connect(this.state.event);
                window.addEventListener('beforeunload', (event) => {
                    // Cancel the event as stated by the standard.
                    event.preventDefault();
                    this.closeSocket();
                    // Chrome requires returnValue to be set.
                    event.returnValue = '';
                });
            }
            this.setState({ courses: resp.courses });
            // convertObstacles(resp.obstacles).then(resp2=>{
            //     this.setState({obstacles: resp2, runOrder: resp.runOrder, leaderboard: resp.leaderboard});
            // })

            //LIST COURSE TYPES ABOVE FOR USER TO SELECT
        }).catch(err => {
            console.log(err);
        })
    }

    setCourse(type) {
        var table;
        var resultStr;
        
        switch (type) {
            case 'open':
                table = 'open_results';
                resultStr = 'o_result_id';
                break;
            case 'speed':
                table = 'speed_results';
                resultStr = 's_result_id';
                break;
            case 'hybrid':
                table = 'hybrid_results';
                resultStr = 'h_result_id';
                break;
            case 'burnout':
                table = 'burnout_results';
                resultStr = 'b_result_id';
                break;
            default:
                table = 'overall_results';
        }
        // this.setState({showCourseSelect: false, showBoardSelect: true });
        //console.log(this.state.courses[indx].course_id);
        console.log(table);
        getCompAthletes(this.state.event, table).then(aths=>{
            this.setState({ runOrder: aths.runOrder, leaderboard: aths.leaderboard, result_table: table, resultIDString: resultStr, showCourseSelect: false, showBoardSelect: true, showLeaderboard: false, showRunOrder: true });
        }).catch(err => {
            console.log(err);
        });
    }

    setBoard(type) {
        
        if (type == "LB") {
            this.setState({ showLeaderboard: true, showRunOrder: false });
        } else {
            this.setState({ showLeaderboard: false, showRunOrder: true });
        }
    }

    setScorecard(athlete, result_id, originArr, course_id) {
        document.getElementById('resultsEntry').scrollTop = 0;
        document.getElementById('pageContainer').scrollTop = 0;
        var elementPos = this.state[originArr].map(function (x) { return x.athlete_id; }).indexOf(athlete);
        var indx;
        for (var i = 0; i < this.state.courses.length; i++) {
            if (this.state.courses[i].course_id == course_id) {
                indx = i;
                break;
            }
        }

        getCourseObstacles(course_id).then(obs => {
            convertObstacles(obs.obstacles).then(obs2 => {
                checkActiveScorecard(this.state[originArr], obs2, athlete).then(resp => {
                    console.log(resp);
                    this.setState({ obstacles: obs2, courseIndex: indx,
                        currentAthleteIndex: elementPos, currentAthlete: athlete, currentAthleteFirst: this.state[originArr][elementPos].first_name, currentAthleteLast: this.state[originArr][elementPos].last_name, currentResult: result_id, showRunOrder: false, showLeaderboard: false,
                        showBoardSelect: false, showScorecard: true, obstacles: resp.obstacles, tieBreakMin: resp.min, tieBreakSec: resp.sec,
                        tieBreakMs: resp.ms, existingScorecard: resp.existingScorecard, obstacleIndex: 0, firstFail: resp.first_fail, totalFails: resp.total_fails, currentAthleteGender: this.state[originArr][elementPos].gender
                    });
                    document.getElementById('resultsEntry').scrollTop = 0;
                }).catch(err => {
                    console.log(err);
                });
                console.log(obs);
                this.setState({ obstacles: obs2});
            })

        }).catch(err => {
            console.log(err);
        });
    }

    createPointRow(item) {
        let table = []
        for (let i = 0; i < item.choices.length; i++) {
            table.push(
                <div className={item.choices[i].selected == true ? "obstacleBoxContent activeBox" : "obstacleBoxContent"} onClick={e => this.addPoints(item, item.choices[i].key)}>{item.choices[i].value == 1 ? <span>Clear</span> : <span>Fail</span>}</div>
            )
        }
        return table
    }

    checkTie() {
        var tie = false;
        var tieOB;
        var tieMin = '';
        var tieSec = '';
        var tieMS = '';
        this.state.obstacles.forEach((item, index) => {
            for (var i = 0; i < item.choices.length; i++) {
                if (tie == false && i !== (item.choices.length - 1) && item.choices[i].selected == true) {
                    tie = true;
                    tieOB = item.obOrder - 1;
                    console.log(tieOB);
                    if (index > 0 && this.state.obstacles[index - 1].time != '') {
                        tieMin = this.state.obstacles[index - 1].time.split(":")[1];
                        tieSec = this.state.obstacles[index - 1].time.split(":")[2].split('.')[0];
                        tieMS = this.state.obstacles[index - 1].time.split(":")[2].split('.')[1];
                    } else if (index == 0 && item.time != '') {
                        tieMin = item.time.split(":")[1];
                        tieSec = item.time.split(":")[2].split('.')[0];
                        tieMS = item.time.split(":")[2].split('.')[1];
                    }
                    this.setState({ tiebreakerOb: tieOB, tieBreakMin: tieMin, tieBreakSec: tieSec, tieBreakMs: tieMS });
                    break;
                }
            }
            if (tie == false) {
                if (this.state.obstacles[this.state.obstacles.length - 1].time != '') {
                    tieMin = item.time.split(":")[1];
                    tieSec = item.time.split(":")[2].split('.')[0];
                    tieMS = item.time.split(":")[2].split('.')[1];
                }
                this.setState({ tiebreakerOb: "", tieBreakMin: tieMin, tieBreakSec: tieSec, tieBreakMs: tieMS });
            }
        });
    }

    convertTime(min, sec, ms) {
        return `${'00'}:${zeroPad(parseInt(min))}:${zeroPad(parseInt(sec))}.${zeroPad(parseInt(ms))}`;
    }

    addPoints(item, c_id, time, val) {
        console.log(item);
        let firstFail = this.state.firstFail;
        if (val < 1) {
            if (this.state.firstFail == 0) {
                firstFail = this.state.obstacleIndex + 1;
            }
            item.fail.push({ num: item.fail.length + 1, time: timeFormat(time, 'full') });
            item.status = 'fail';
        } else {
            item.status = 'clear';

        }
        for (var i = 0; i < item.choices.length; i++) {
            if (item.choices[i].key != c_id) {
                item.choices[i].selected = false;
            } else {
                item.choices[i].selected = true;
                if (time) {
                    item.time = timeFormat(time, 'full');
                }
                if (item.choices[i].value > 0) {
                    item.status = 'clear';
                } else {
                    item.status = 'fail';
                }

                this.checkTie();
            }
        }
        this.setState({ obstacles: this.state.obstacles, firstFail: firstFail, totalFails: this.getTotalFails() });
    }

    getFirstFail() {
        var fail = 0;
        for (var x = 0; x < this.state.obstacles.length; x++) {
            if (this.state.obstacles[x].fail.length > 0) {
                fail = x + 1;
                break;
            };
        }
        return fail;
    }

    confirmSubmission(val) {
        if (val == 'scorecard') {
            this.setState({ openDialog: true });
        } else {
            this.setState({ openFinalDialog: true });
        }
    }

    submitScorecard() {
        document.getElementById('resultsEntry').scrollTop = 0;
        document.getElementById('pageContainer').scrollTop = 0;
        var tiebreakTime = this.convertTime(this.state.tieBreakMin, this.state.tieBreakSec, this.state.tieBreakMs);
        calculateScore(this.state.obstacles).then(resp => {
            var points = resp.score;
            var resultString = resp.resStr;
            if (resultString.length !== this.state.obstacles.length && resultString.length > 0) {
                this.setState({ openDialog: false, errorDialog: true, errorMsg: "At least one obstacle is missing a point selection!", errType: 'missing_obstacles' });
            } else if (tiebreakTime == '00:00:00.00' && resultString.length > 0) {
                this.setState({ openDialog: false, errorDialog: true, errorMsg: "Tiebreak time is zero and should have a value!" });
            } else {
                postScorecard(this.state.currentResult, this.state.event, points, this.state.tiebreakerOb, tiebreakTime, JSON.stringify(resultString), this.state.currentAthleteGender, this.state.currentAthlete, this.state.firstFail, this.state.totalFails, this.state.courses[this.state.courseIndex].course_id, this.state.result_table).then(resp2 => {
                    console.log(resp2);
                    if (this.state.live_clock == true || this.state.live_clock == 'true') {
                        var wsObj = {uid: "0", clock_status: "athlete_submitted", event_id: this.state.event, athlete: this.state.currentAthleteFirst + ' ' + this.state.currentAthleteLast, gender: this.state.currentAthleteGender, result_table: this.state.result_table, athlete_id: this.state.currentAthlete}
                        this.sendMessage({ "action": "clockUpdate", "sub_action": "athlete_submitted", "message": JSON.stringify(wsObj) }); //send to websocket if live
                    }
                    if ("affectedRows" in resp2.status) {
                        resetObstacleArr(this.state.obstacles).then(resp3 => {
                            this.setState({
                                showScorecard: false, showRunOrder: true, showLeaderboard: false, currentAthlete: '', currentResult: '', openDialog: false,
                                tieBreakMin: '', tieBreakSec: '', tieBreakMs: '', tiebreakerOb: '', obstacles: resp3,
                                runOrder: resp2.runOrder, leaderboard: resp2.leaderboard, showBoardSelect: true,
                                existingScorecard: false, totalFails: 0, firstFail: 0
                            });
                        });
                    } else {
                        this.setState({ openDialog: false, errorDialog: true, errorMsg: "No connection to the database! Please try submitting again or, copy the results on paper and submit when internet is back online." });
                    }
                });
            }
        });
    }

    failAthlete = () => {
        var time_ref = '';
        this.state.obstacles.forEach(function (item, index) {
            if (item.choices[0].selected == false && item.choices[1].selected == false) {
                item.choices[0].selected = true;
                item.time = time_ref;
                item.fail.push({ num: item.fail.length + 1, time: time_ref });
            } else {
                time_ref = item.time;
            }

        });
        this.checkTie();
        this.closeDialog();
    }


    goBack() {
        document.getElementById('resultsEntry').scrollTop = 0;
        document.getElementById('pageContainer').scrollTop = 0;
        if (this.state.showScorecard == true) {
            resetObstacleArr(this.state.obstacles).then(resp => {
                this.setState({ showScorecard: false, showRunOrder: true, showLeaderboard: false, showBoardSelect: true, currentAthlete: '', obstacles: resp, existingScorecard: false, totalFails: 0, firstFail: 0 });
            });
        } else {
            this.setState({ showRunOrder: false, showLeaderboard: false, showBoardSelect: false, courseIndex: '', courses: [], athletes: [], event: '', showEventSelect: true });
        }
    }

    closeDialog = (type) => {
        this.setState({ openDialog: false, openFinalDialog: false, errorDialog: false, editTime: false });
    }

    scoreObstacle = (time, obstacle) => {
        console.log(obstacle);
        this.addPoints(this.state.obstacles[this.state.obstacleIndex], obstacle.key, time, obstacle.value);
        if (this.state.obstacleIndex < (this.state.obstacles.length - 1) && obstacle.value > 0) {
            this.setState({ obstacleIndex: this.state.obstacleIndex + 1 });
            // if(obstacle.value ==0){
            //     for(var i=this.state.obstacleIndex; i<this.state.obstacles.length; i++){
            //         console.log(this.state.obstacles[i]);
            //         this.addPoints(this.state.obstacles[i], this.state.obstacles[i].choices[0].key)
            //     }
            // }
        }

    }

    editObstacleFunc(obstacle, ind) {
        var min = '';
        var sec = '';
        var ms = '';
        if (obstacle.time != '') {
            min = obstacle.time.split(":")[1];
            sec = obstacle.time.split(":")[2].split('.')[0];
            ms = obstacle.time.split(":")[2].split('.')[1];
        }
        this.setState({ editTime: true, editTimeMin: min, editTimeSec: sec, editTimeMS: ms, editObInd: ind });
    }

    submitNewTime() {
        console.log(this.state.addFailArr);
        console.log(this.state.editObInd);
        console.log(this.state.obstacles[this.state.editObInd]);
        this.state.obstacles[this.state.editObInd].time = this.convertTime(this.state.editTimeMin, this.state.editTimeSec, this.state.editTimeMS);
        let newArr = this.state.obstacles;
        this.state.addFailArr.forEach((item, index) => {
            this.state.obstacles[this.state.editObInd].fail.push(item);
        });
        this.setState({ editTime: false, editTimeMin: '', editTimeSec: '', editTimeMS: '', addFailArr: [], firstFail: this.getFirstFail(), totalFails: this.getTotalFails() });
        this.checkTie();
    }

    confirmRemoveFail(ind) {
        this.setState({ confirmRemove: true, removeInd: ind });
    }

    getTotalFails = () => {
        console.log(this);
        var fails = 0;
        for (var x = 0; x < this.state.obstacles.length; x++) {
            if (this.state.obstacles[x].status == 'clear') {
                fails = fails + this.state.obstacles[x].fail.length;
            };
        }
        return fails;
    }

    removeFail(str) {
        if (str == 'yes') {
            this.state.obstacles[this.state.editObInd].fail.splice(this.state.removeInd, 1);
        }
        this.setState({ confirmRemove: false, firstFail: this.getFirstFail(), totalFails: this.getTotalFails() });
    }

    removeFailArr(ind) {
        this.state.addFailArr.splice(ind, 1);
        this.setState({ addFailArr: this.state.addFailArr });
    }

    addFail() {
        let newArr = this.state.addFailArr;
        newArr.push({ num: this.state.obstacles[this.state.editObInd].fail.length + this.state.addFailArr.length + 1, time: '' });
        this.setState({ addFailArr: newArr });
    }



    FailArrTime = ind => event => {
        let newArr = this.state.addFailArr;
        newArr[ind].time = event.target.value;
        this.setState({
            addFailArr: newArr
        })

    }

    // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    // Good to go
    componentDidMount() {
        console.log(this);
        var queryObj = queryString.parse(this.props.location.search);
        var liveCheck = false;
        if (queryObj.live_clock) {
            liveCheck = queryObj.live_clock;
        }
        
        getComps().then(resp => {
            this.setState({ eventsArr: resp.comps, showEventSelect: true, live_clock: liveCheck });
        });
    }

    componentWillUnmount() {
        this.closeSocket();
    }
    // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx    

    render() {
        return <div id="resultsEntry" className="resultsEntry mainDiv">
            <NSNHeader title='NSN Scoreboard' link='' linkTitle=''></NSNHeader>

            <Row>
                <div id="pageContainer" className="pageContainer">

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <label class="switch">
                                <input type="checkbox" checked={this.state.live_clock == 'true'} onChange={this.handleCheck('live_clock')} />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div style={{ fontWeight: "bold", color: "#f32121", marginLeft: "15px" }}>Live</div>

                    </div>

                    {this.state.showEventSelect == true ?
                        <div>
                            <div className="subTitle">Please Select a Competition</div>
                            <Row wrap style={{ marginTop: "20px" }}>
                                {this.state.eventsArr.filter(res=>res.finalize_results==0).map((item, index) => {
                                    return <div style={{ margin: "10px" }}>
                                        <div className="darkChoiceBox" onClick={e => this.setEvent(item.comp_id)}>{item.gym_name}</div>
                                    </div>
                                })}
                            </Row></div> : null}
                    {this.state.showCourseSelect == true ?
                        <div>
                            <div className="subTitle">Please Select A Course</div>
                            <Row horizontal="start" wrap style={{ marginTop: "20px" }}>
                                {/* {this.state.courses.map((item, index) => (
                                    <div className="darkChoiceBox" style={{ margin: "5px" }} onClick={e => this.setCourse(item.course_id)}>{item.course_type} {item.description}</div>
                                ))} */}
                                <div className="darkChoiceBox" style={{ margin: "5px" }} onClick={e => this.setCourse('open')}>Open</div>
                                <div className="darkChoiceBox" style={{ margin: "5px" }} onClick={e => this.setCourse('hybrid')}>Hybrid</div>
                                <div className="darkChoiceBox" style={{ margin: "5px" }} onClick={e => this.setCourse('speed')}>Speed</div>
                                <div className="darkChoiceBox" style={{ margin: "5px" }} onClick={e => this.setCourse('burnout')}>Burnout</div>
                            </Row> </div> : null}

                    {this.state.showBoardSelect == true ?
                        <div>
                            <Row horizontal="spaced" wrap style={{ marginTop: "20px", alignItems: 'center' }}>
                                <div className={this.state.showRunOrder == true ? "darkChoiceBox activeBox" : "darkChoiceBox"} onClick={e => this.setBoard("RO")}>Run Order</div>
                                <div><FontAwesomeIcon onClick={e => this.goBack()} className="backIcon" icon="arrow-alt-circle-left" style={{ fontSize: "24px" }} />{this.state.result_table == 'open_results' ? <span className="reTitle">Open Course</span> : this.state.result_table == 'speed_results' ? <span className="reTitle">Speed Course</span>
                                    : this.state.result_table == 'hybrid_results' ? <span className="reTitle">Hybrid Course</span> : <span className="reTitle">Burnout Course</span>}
                                </div>
                                <div className={this.state.showLeaderboard == true ? "darkChoiceBox activeBox" : "darkChoiceBox"} onClick={e => this.setBoard("LB")}>Leaderboard</div>
                            </Row> </div> : null}

                    {this.state.showRunOrder == true ?
                        <div>
                            <div className="subTitle"><FontAwesomeIcon onClick={e => this.goBack()} className="backIcon" icon="arrow-alt-circle-left" />Run Order</div>
                            <Column horizontal="spaced" wrap style={{ marginTop: "20px" }}>
                                {this.state.result_table == 'open_results' ?
                                this.state.runOrder.map((item, index) => {
                                    if(item.run_order!=null){
                                    return <div className={item.points !== null ? "athleteBox completed" : "athleteBox"} onClick={e => this.setScorecard(item.athlete_id, item[this.state.resultIDString], "runOrder", item.course_id)}>
                                        <Row horizontal='flex-start' vertical='center'>
                                            <div style={{marginRight: "20px"}}>{item.run_order}.</div>
                                            <div>{item.first_name + ' ' + item.last_name}</div>
                                            {/* <div><div>{item.points}</div><div style={{ fontSize: ".8em", color: "#979797" }}>Pts.</div></div> */}
                                        </Row>
                                    </div>
                                    }
                                })
                            :
                            // this.state.runOrder.sort((a, b) => a.gender.localeCompare(b.gender) || a.run_order - b.run_order).map((item, index) => {
                                this.state.runOrder.sort((a, b) =>  a.run_order - b.run_order).map((item, index) => {
                                    if(item.run_order!=null){
                                return <div className={item.points !== null ? "athleteBox completed" : "athleteBox"} onClick={e => this.setScorecard(item.athlete_id, item[this.state.resultIDString], "runOrder", item.course_id)}>
                                    <Row horizontal='flex-start' vertical='center'>
                                        <div style={{marginRight: "20px"}}>{item.run_order}.</div>
                                        <div>{item.first_name + ' ' + item.last_name}</div>
                                        {/* <div><div>{item.points}</div><div style={{ fontSize: ".8em", color: "#979797" }}>Pts.</div></div> */}
                                    </Row>
                                </div>
                                    }
                            })}
                            </Column>
                        </div> : null}

                    {this.state.showLeaderboard == true ?
                        <div>
                            <div className="subTitle"><FontAwesomeIcon onClick={e => this.goBack()} className="backIcon" icon="arrow-alt-circle-left" />Leaderboard</div>

                            <div className="leaderboardDivision">

                                <div className={this.state.toggleMobileLeaderboard == true ? "athleteDivision hideMobile" : "athleteDivision"}>
                                    <div ref={this.maleRef} className="athleteBox">Male</div>
                                    <div className="scrollLink"><span href="javascript:void(0)" onClick={e => this.toggleMobileLB()}>Show Female</span></div>
                                    {this.state.leaderboard.map((item, index) => {
                                        if (item.gender == "Male") {
                                            return <div className={item.points !== null ? "athleteBox completed" : "athleteBox"} onClick={e => this.setScorecard(item.athlete_id, item[this.state.resultIDString], "leaderboard", item.course_id)}>
                                                <Row horizontal='space-around' vertical='center'>
                                                    <div>{item.athlete_rank}.</div>
                                                    <div>{item.first_name + ' ' + item.last_name}</div>
                                                    <div>
                                                        <Row>
                                                            <div>
                                                                <div>{item.points}</div>
                                                                <div style={{ fontSize: ".8em", color: "#979797" }}>Pts.</div>
                                                            </div>
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <div>{item.total_fails}</div>
                                                                <div style={{ fontSize: ".8em", color: "#979797" }}>TF</div>
                                                            </div>
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <div>{item.first_fail}</div>
                                                                <div style={{ fontSize: ".8em", color: "#979797" }}>FF</div>
                                                            </div>

                                                        </Row>
                                                    </div>
                                                </Row>
                                                <Row horizontal='center'>
                                                    <div className="tieTime">{item.tiebreak_time.substr(3)}</div>
                                                </Row>
                                            </div>
                                        }
                                    })}
                                </div>

                                <div className={this.state.toggleMobileLeaderboard == true ? "athleteDivision" : "athleteDivision hideMobile"}>
                                    <div ref={this.femaleRef} className="athleteBox">Female</div>
                                    <div className="scrollLink"><span onClick={e => this.toggleMobileLB()}>Show Male</span></div>
                                    {this.state.leaderboard.map((item, index) => {
                                        if (item.gender == "Female") {
                                            return <div className={item.points !== null ? "athleteBox completed" : "athleteBox"} onClick={e => this.setScorecard(item.athlete_id, item.result_id, "leaderboard", item.course_id)}>
                                                <Row horizontal='space-around' vertical='center'>
                                                    <div>{item.athlete_rank}.</div>
                                                    <div>{item.first_name + ' ' + item.last_name}</div>
                                                    <div><div>{item.points}</div><div style={{ fontSize: ".8em", color: "#979797" }}>Pts.</div></div>
                                                </Row>
                                                <Row horizontal='center'>
                                                    <div className="tieTime">{item.tiebreak_time.substr(3)}</div>
                                                </Row>
                                            </div>
                                        }
                                    })}
                                </div>
                            </div>

                        </div> : null}

                    {this.state.showScorecard == true ?
                        <div>
                            <Row flexGrow={1} flexBasis='auto' wrap>
                                <div className="scoreboardHeader">
                                    <div className="subTitle" style={{ marginTop: "0px" }}><FontAwesomeIcon onClick={e => this.goBack()} className="backIcon" icon="arrow-alt-circle-left" />Scorecard</div>
                                    <div className="scName"> | {this.state.currentAthleteFirst + ' ' + this.state.currentAthleteLast}</div>
                                </div>

                                <div style={{ width: "100%" }}>
                                    <Column>
                                        {this.state.existingScorecard == false ?
                                            <Stopwatch obstacleScore={this.scoreObstacle}
                                                total_fails={this.getTotalFails}
                                                choices={this.state.obstacles[this.state.obstacleIndex].choices}
                                                obstacle={this.state.obstacles[this.state.obstacleIndex]}
                                                obstacleArr={this.state.obstacles}
                                                obstacleInd={this.state.obstacleIndex} complete={this.state.scoreCardComplete}
                                                obstacleCnt={this.state.obstacles.length}
                                                live_clock={this.state.live_clock}
                                                event_id={this.state.comp_type == "4" ? this.state.event + 2 : this.state.event}
                                                gender={this.state.runOrder[this.state.currentAthleteIndex].gender}
                                                athlete_name={this.state.currentAthleteFirst + " " + this.state.currentAthleteLast}
                                                athlete_id={this.state.currentAthlete}
                                                reset_time={this.state.courses[this.state.courseIndex].reset_time}
                                                result_table={this.state.result_table} />
                                            : null}
                                        <Column horizontal="center" style={{ marginTop: '35px' }}>
                                            {this.state.obstacles.map((item, index) => {
                                                return <div className="obstacleBox">
                                                    <div className="obstacleBoxTitle">{item.obstacle}</div>
                                                    <Row horizontal="center">
                                                        {this.createPointRow(item)}
                                                    </Row>
                                                    <Row horizontal="center">
                                                        {item.fail.map((fail_item, fail_index) => {
                                                            return <div>
                                                                <FontAwesomeIcon className="failIcon" icon="times" />
                                                            </div>
                                                        })}
                                                        {item.status === 'clear' && <div><FontAwesomeIcon className="clearIcon" icon="check" /></div>}
                                                    </Row>
                                                    <Row horizontal='center' style={{ padding: "5px", borderTop: "1px solid rgb(230,230,230)" }}><div>{item.time.substr(3)}</div>
                                                        <div align="right" style={{ fontSize: '11px', cursor: "pointer", marginLeft: '15px', color: "rgb(148,194,228)" }}><a onClick={e => this.editObstacleFunc(item, index)}>edit obstacle</a></div>
                                                    </Row></div>
                                            })}
                                        </Column>
                                        <Row horizontal="center" style={{ marginTop: "20px" }}>
                                            <div style={{ marginRight: "30px" }}>
                                                <div style={{ color: "#dadada" }}>First Fail</div>
                                                <div align="middle" style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px", color: "#dadada" }}>{this.state.firstFail}</div>
                                            </div>

                                            <div style={{ marginRight: "30px" }}>
                                                <div style={{ color: "#dadada" }}>Total Fails</div>
                                                <div align="middle" style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px", color: "#dadada" }}>{this.state.totalFails}</div>
                                            </div>

                                            <div style={{ marginRight: "30px" }}>
                                                <div style={{ color: "#dadada" }}>Tiebreaker Obstacle</div>
                                                <div align="middle" style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px", color: "#dadada" }}>{this.state.tiebreakerOb}</div>
                                            </div>
                                            <div>
                                                <div style={{ color: "#dadada" }}>Tiebreaker Time</div>
                                                <div style={{ marginTop: "10px", color: "#dadada" }}>
                                                    <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="min" value={this.state.tieBreakMin} onChange={this.handleChange('tieBreakMin')} /></span>
                                                    <span style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: "18px", fontWeight: "bold" }}>:</span>
                                                    <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="sec" value={this.state.tieBreakSec} onChange={this.handleChange('tieBreakSec')} /></span>
                                                    <span style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: "18px", fontWeight: "bold" }}>.</span>
                                                    <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="ms" value={this.state.tieBreakMs} onChange={this.handleChange('tieBreakMs')} /></span>
                                                </div>
                                            </div>
                                        </Row>
                                        <Row horizontal="center">
                                            {this.state.existingScorecard == false ? <button className='submitBtn' onClick={e => this.confirmSubmission('scorecard')}>Submit</button>
                                                : <button className='updateBtn' onClick={e => this.confirmSubmission('scorecard')}>Update</button>}
                                        </Row>
                                    </Column>
                                </div>
                            </Row>
                        </div> : null}
                </div>
            </Row>

            <Dialog
                open={this.state.openDialog}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Are you sure you want to submit this scorecard?</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.state.existingScorecard == false ?
                        <Button type='submit' onClick={e => this.submitScorecard()} color="primary">
                            Submit
                        </Button>
                        :
                        // <Button type='submit' onClick={e => this.updateScorecard()} color="primary">
                        <Button type='submit' onClick={e => this.submitScorecard()} color="primary">
                            Update
                        </Button>
                    }
                    <Button type='submit' onClick={this.closeDialog} color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={this.state.errorDialog}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">ERROR: {this.state.errorMsg}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>Results NOT submitted.</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.state.errType == 'missing_obstacles' ?
                        <Button type='submit' onClick={e => this.failAthlete()}>
                            Fail Athlete
                        </Button> : null}
                    <Button type='submit' onClick={this.closeDialog} color="default">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>



            <Dialog
                open={this.state.editTime}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Edit Obstacle Score:</DialogTitle>
                <DialogContent>

                    {this.state.obstacles.length > 0 && this.state.obstacles[this.state.editObInd].fail.map((fail, index) => {
                        return <div>
                            <Row style={{ margin: "10px 0px" }}>
                                <div onClick={e => this.confirmRemoveFail(index)}> <FontAwesomeIcon className="failIcon" icon="times" /></div>
                                <div className="failIcon" style={{ margin: "0px 10px" }}>{fail.num}</div>
                                <div className="failIcon" style={{ margin: "0px 10px" }}>{fail.time}</div>
                            </Row>
                        </div>
                    })}

                    {this.state.confirmRemove && <div>
                        <div>
                            Are you sure you want to remove this fail from this obstacle?
                        </div>
                        <div>
                            <button className='submitBtn' onClick={e => this.removeFail('yes')}>Yes</button>
                            <button className='noBtn' onClick={e => this.removeFail('no')}>No</button>
                        </div>
                    </div>}

                    <div>
                        <span style={{ color: "red", textDecoration: "underline", cursor: "pointer" }} onClick={e => this.addFail()}>Add Fail</span>
                    </div>
                    {this.state.addFailArr.length > 0 && this.state.addFailArr.map((fail, index) => {
                        return <div>
                            <Row style={{ margin: "10px 0px" }}>
                                <div onClick={e => this.removeFailArr(index)}> <FontAwesomeIcon className="failIcon" icon="times" /></div>
                                <div className="failIcon" style={{ margin: "0px 10px" }}>{fail.num}</div>
                                <div>
                                    <div style={{ fontSize: "12px" }}>Format time as "HH:MM:SS.MS" (00:00:00.00), exactly do not leave out any characters</div>
                                    <div><input style={{ width: '150px' }} className='skillInput' type="text" placeholder="time" value={fail.time} onChange={this.FailArrTime(index)} /></div>
                                </div>
                            </Row>
                        </div>
                    })}


                    <DialogContentText>
                        <div>Please enter the new time and hit Submit</div>
                        <div style={{ marginTop: "10px" }}>
                            <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="min" value={this.state.editTimeMin} onChange={this.handleChange('editTimeMin')} /></span>
                            <span style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: "18px", fontWeight: "bold" }}>:</span>
                            <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="sec" value={this.state.editTimeSec} onChange={this.handleChange('editTimeSec')} /></span>
                            <span style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: "18px", fontWeight: "bold" }}>.</span>
                            <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="ms" value={this.state.editTimeMS} onChange={this.handleChange('editTimeMS')} /></span>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type='submit' onClick={e => this.submitNewTime()} color="default">
                        Submit
                    </Button>
                    <Button type='submit' onClick={this.closeDialog} color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={this.state.openFinalDialog}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Are you sure you want to submit these results?</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type='submit' onClick={e => this.finalizeResults()} color="primary">
                        Submit
                    </Button>
                    <Button type='submit' onClick={this.closeDialog} color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {this.state.live_clock == "true" ? <div className="liveBanner">
                <div className="liveContent"><FontAwesomeIcon icon="circle" style={{ marginRight: "10px", color: "red" }}></FontAwesomeIcon>Live</div>
            </div> : null}
        </div>;
    }
}

export default ResultsEntry;