import React, { Component } from 'react';
import '../style/stopwatch.css';
import '../style/live_clock.css';

import Timer from './Timer';
import NSC_Logo from '../static/Logo_Clock_Graphic.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import ResetTimer from '../components/ResetTimer';
import { getLatestMessage } from '../api/api';


library.add(faCircle, faTimes);

function getDefaultState() {
  return {
    isRunning: false,
    time: 0,
    timeList: [],
    start: 0,
    stopTime: 0,
    restartTime: 0,
    adjustedTime: 0,
    dataFromServer: '',
    ws: null,
    timerRef: null,
    athlete: '',
    curr_obstacle: 1,
    total_obstacles: 1,
    fails: 0,
    obstacle_result: 'clear',
    event_id: null,
    stat_ind: 0,
    ro_ind: 0,
    course_desc: '',
    obstacle: '',
    reset_time: 0,
    fail: false,
    fail_cnt: 0,
    compId: '',
    delay: 0
  }
}

class LiveClock extends Component {

  constructor(props) {
    super(props);
    this.state = getDefaultState();
    this.timerRef = null;
  }

  timeout = 250; // Initial timeout duration as a class variable

  /**
   * function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */

  start = (start, res, adj) => {
    if (this.state.stopTime != 0) {
      this.setState({ restartTime: res, adjustedTime: adj });
    } else {
      this.setState({ start: start });
    }
    if (this.state.isRunning == false) {
      this.setState({
        isRunning: true
      }, () => {
        this.timerRef = setInterval(
          () => {
            var delta = Date.now() - this.state.start - (this.state.adjustedTime); // milliseconds elapsed since start
            this.updateTimer(delta - this.state.delay);
            // this.updateTimer(delta - 1750);
          }, 10
        )
      });
    }
  }

  stop = (stop, obs) => {
    // console.log('stop function');
    this.setState({
      isRunning: false,
      stopTime: stop,
      obstacle_result: obs
    }, () => {
      clearInterval(this.timerRef);
      var delta = this.state.stopTime - this.state.start - (this.state.adjustedTime);
      this.updateTimer(delta);
    });
  }

  connect = (compId) => {
    console.log(compId);
    var ws = new WebSocket("wss://7o5i09cjfa.execute-api.us-east-2.amazonaws.com/production?comp_id=" + compId);
    let that = this; // cache the this
    var connectInterval;

    // websocket onopen event listener
    ws.onopen = () => {
      console.log("connected websocket main component");
      this.setState({ ws: ws });
      that.timeout = 250; // reset timer to 250 on open of websocket connection 
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    ws.onmessage = evt => {
      // listen to data sent from the websocket server

      const message = evt.data;
      console.log(evt);

      if (evt.data.length > 10) {
        this.updateGraphics(evt.data);
      }

    }

    // websocket onclose event listener
    ws.onclose = e => {
      // console.log('Closing');
      // console.log(e);
      if (e.type !== 'close') {
        console.log(
          `Socket is closed. Reconnect will be attempted in ${Math.min(
            10000 / 1000,
            (that.timeout + that.timeout) / 1000
          )} second.`,
          e.reason
        );

        that.timeout = that.timeout + that.timeout; //increment retry interval
        connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
      }
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };
  };

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  sendMessage = (data) => {
    const { ws } = this.state // websocket instance passed as props to the child component.
    // console.log(data);
    try {
      ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
      // console.log(error) // catch error
    }
  }

  closeSocket = () => {
    const { ws } = this.state // websocket instance passed as props to the child component.
    // console.log(data);
    try {
      ws.close() //send data to the server
    } catch (error) {
      // console.log(error) // catch error
    }
  }





  updateTimer(extraTime) {
    // console.log(this.props.live_clock);
    const { time } = this.state;
    if (this.state.isRunning == true) {

    } else {
      this.timerRef = null;
    }
    this.setState({ time: extraTime });

  }

  hideReset = () => {
    this.setState({ fail: false });
  }

  hideReset = () => {
    this.setState({ fail: false });
  }

  updateGraphics(message){
    var parseData = JSON.parse(message.slice(0, -1));
        console.log(parseData);
        // console.log(this);
        switch (parseData.clock_status) {
          case "warming":
            var courseDesc;
            switch (parseData.result_table) {
              case 'open_results':
                courseDesc = 'Open Round';
                break;
              case 'speed_results':
                courseDesc = 'Speed Course';
                break;
              case 'hybrid_results':
                courseDesc = 'Hybrid Course';
                break;
              case 'burnout_results':
                courseDesc = 'Burnout Course';
                break;

            }
            this.setState({
              athlete: parseData.athlete, total_obstacles: parseData.total_obstacles, isRunning: false,
              time: 0,
              start: 0,
              stopTime: 0,
              restartTime: 0,
              adjustedTime: 0,
              dataFromServer: '',
              curr_obstacle: 1,
              fails: 0,
              fail: false,
              obstacle_result: "clear",
              course_desc: courseDesc,
              reset_time: parseData.reset_time,
              fail_cnt: 0
            });
            break;
          case "start":
            this.start(parseData.startTime, parseData.restartTime, parseData.adjustedTime);
            break;
          case "stop":
            setTimeout(() => {
              this.stop(parseData.stopTime, parseData.obstacle_result);
              this.setState({ fails: parseData.fails });
            }, this.state.delay);
            // }, 1750);
            break;
          case "running":
            var failCnt = this.state.fail_cnt;
            var showFail = parseData.fail;
            if (parseData.fail == true) {
              failCnt += 1;
              if (failCnt == 3) {
                showFail = false;
              }
            } else {
              failCnt = 0;
            }
            if(this.state.isRunning == false){
              this.start(parseData.startTime, parseData.restartTime, parseData.adjustedTime);
            }
            setTimeout(() => {
              this.setState({ curr_obstacle: parseData.current_obstacle, fails: parseData.fails, obstacle: parseData.obstacle, fail: showFail, fail_cnt: failCnt, athlete: parseData.athlete, total_obstacles: parseData.total_obstacles });
            }, this.state.delay);
            // }, 1750);

            break;
        }
  }

  getMessage(){
    this.check();
    getLatestMessage().then(resp=>{
      var arr = resp.message.Items;
      if(arr.length > 0){
        for(var i=0; i<arr.length; i++){
          if(arr[i].message_id == 'clock'){
            this.updateGraphics(arr[i].message);
            break;
          }
        }
      }
    });
  }

  componentDidMount() {
    console.log(this);
    // setTimeout(() => {
      console.log(this.props.compId);
      this.connect(this.props.compId);
      this.setState({delay: this.props.delay});
    // }, 1000);
    // console.log(this);
    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      this.closeSocket();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    });
  }

  componentWillUnmount() {
    this.closeSocket();
  }

  render() {

    const { isRunning, time, timeList } = this.state;

    return (
      <div>
        <div>
        <button className='nsnBtn' onClick={e => this.getMessage()}>Get Message</button>
        </div>
        <div>
          <div className="V3LCContainer">
              <div className="V3LCLogoBox">
                <div style={{height: "48px", paddingTop: "8px"}}><img alt='NSC_Logo' src={NSC_Logo} width="90" height="auto" /></div>
              </div>
              
              <div className="V3InfoBox">
                <div className="V3AthleteBox">{this.state.athlete.slice(0, 18)}</div>
              </div>
              <div className="V3LCclockBox">
                <div className="V3DataContainer">
                  <div style={{width: "120px"}}>
                <div className="V3ThreeStrikes">
                {[...Array(this.state.fail_cnt)].map((elementInArray, index) => (
                      <FontAwesomeIcon icon="times" className="LCfailIcon"></FontAwesomeIcon>
                    )
                    )}
                </div>
                <div className="V3Timer"><Timer time={time} displayType={'live'} /></div>
                <div className="V3ResetTime">{this.state.fail && this.state.reset_time > 0 && <ResetTimer style="none" resetTimerStop={this.hideReset} timeRef={this.state.reset_time} cancelReset={this.hideReset} />}</div>
                </div>

                <div>

                <div className="V3LCObstacles">
                  <div>{this.state.curr_obstacle} / {this.state.total_obstacles}</div>
                </div>
                <div className="V3Fails">
                  Fails: <span className={this.state.fails > 0 ? "V3FailCnt": ""} style={{marginLeft: "5px"}}>{this.state.fails}</span>
                    </div>
                    </div>

                    </div>
              </div>
          </div>
       
        </div>

      </div>
      // <div>
      //   <div>
      //   <button className='nsnBtn' onClick={e => this.getMessage()}>Get Message</button>
      //   </div>
      //   <div>
      //     <div className="V2LCContainer">
      //         <div className="V2LCLogoBox">
      //           <div style={{height: "40px"}}><img alt='NSC_Logo' src={NSC_Logo} width="90" height="auto" /></div>
      //           <div className="V2Fails">
      //             Fails: <span className={this.state.fails > 0 ? "V2FailCnt": ""} style={{marginLeft: "5px"}}>{this.state.fails}</span>
      //             {/* {[...Array(this.state.fails)].map((elementInArray, index) => (
      //                 <FontAwesomeIcon icon="times" className="LCfailIcon"></FontAwesomeIcon>
      //               )
      //               )} */}
      //               </div>
      //         </div>
              
      //         <div className="V2InfoBox">
      //           <div className="V2AthleteBox">{this.state.athlete.slice(0, 18)}</div>
      //           <div className="V2LCObstacles">
      //           {[...Array(this.state.total_obstacles)].map((elementInArray, index) => (
      //                 <div className={(index + 1) < this.state.curr_obstacle ? "V2obstacleIconDiv V2LCclearIcon" : "V2obstacleIconDiv"}>{index + 1}</div>
      //               )
      //               )}
      //           </div>
      //         </div>
      //         <div className="V2LCclockBox">
      //           <div className="V2ThreeStrikes">
      //           {[...Array(this.state.fail_cnt)].map((elementInArray, index) => (
      //                 <FontAwesomeIcon icon="times" className="LCfailIcon"></FontAwesomeIcon>
      //               )
      //               )}
      //           </div>
      //           <div className="V2Timer"><Timer time={time} displayType={'live'} /></div>
      //           <div className="V2ResetTime">{this.state.fail && this.state.reset_time > 0 && <ResetTimer style="none" resetTimerStop={this.hideReset} timeRef={this.state.reset_time} cancelReset={this.hideReset} />}</div>
      //         </div>
      //     </div>
       
      //   </div>

      // </div>
    );
  }
}

export default LiveClock;