import React, { Component } from 'react';
import '../style/main.css';
import NSNHeader from "../components/header";
import HeroImg from "../components/HeroImg";
import NSNFooter from "../components/footer";
import central_img from '../static/Central_Section_Schedule.jpg';
import northeast_img from '../static/Northeast_Section_Schedule.jpg';
import southeast_img from '../static/Southeast_Section_Schedule.jpg';
import west_img from '../static/West_Section_Schedule.jpg';
import sixfive_ninja_logo from '../static/605Logo.jpg';
import ninja_intensity_logo from '../static/ninja_intensity_logo.png';
import champ_img from '../static/Championship_Section_Schedule.jpg';


class YouthChampionshipPage extends Component {

    state = {

    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="mainDiv">
                <NSNHeader title='Ninja Sport Network' link='' linkTitle=''></NSNHeader>
                <div>
                    <HeroImg page="youth_championship" title="NSC Youth Championship"></HeroImg>
                </div>

                <div className="rulesContainer">
                    <div className="NSCTitle" style={{ fontSize: "20px" }}>Summary</div>

                    <p>NSC is excited to expand our youth season to offer more opportunities for athletes to compete under the NSC format that includes the retry system and combined scoring of three seperate disciplines of ninja. We will be hosting qualifiers around the country to offer more access to athletes and expanding to 3 age divisions (8-9 / 10-11 / 12-13 year olds). All athletes will run Hybrid and Speed courses at each qualifier with the top 10 boys and girls also getting to run a burnout course. More details and the full rule book for the season will be released soon. If you are a gym that is interested in hosting click the button below!</p>
                    <p>Athletes that are 13 years old ARE NOT ELIGIBLE to compete if they have placed within the top 10 of the NSC regular season.</p>

                    
{/* <div className="registerCard"> */}
                                    {/* <div className="flex-row" style={{ justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}> */}
                                        {/* <div className="flex-row" style={{alignItems: 'center'}}>
                                            <div><img src={rock_solid_logo} width="150px"></img></div>
                                            <div className="cardTitle">
                                            <div className="gymName" style={{marginBottom: "5px", fontSize: "20px"}}>NSC Youth Championship</div>
                                                <div className="gymInfo" style={{fontSize: "16px"}}>Rock Solid Warrior</div>
                                                <div className="gymInfo" style={{fontSize: "16px"}}>South Apex NC</div>
                                                <div className="gymInfo" style={{fontSize: "16px"}}>Aug 31 & Sep 1, 2024</div>
                                            </div>
                                        </div> */}
                                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButton" href="https://ninjamasterapp.com/events/5166-nsc-youth-championship-ii-rock-solid-warrior" target="_blank">Register</a></div> */}
                                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://docs.google.com/forms/d/e/1FAIpQLSezCUWAQu8r0XNPMMX6xSp-doikWsOWueSAJR9f4nRdyD2-DA/viewform?usp=sf_link" target="_blank">Waitlist</a></div> */}
                                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                                    {/* </div>
                                </div> */}



                                <div className="NSCTitle" style={{ fontSize: "20px" }}>Format & Rules</div>
                                <p>Registration will become available throughout the season, we will be releasing registration dates as they become available on our website and social platforms. Patreon supporters will be granted 1 hour early access to registration, for more information check out our <a style={{color: "rgb(102, 216, 73)"}} target="_blank" href="https://www.patreon.com/c/ninjasportnetwork">Patreon</a>. Patreon is a platform for small content creators and businesses to raise money via crowdfunding and is crucial to the success of NSC.</p>
                                <p>Click the link to view our full rulebook: </p>
                                <div style={{width: "100%"}}><a className="nscBtn" href="https://nsn-assets.s3.us-east-2.amazonaws.com/public-documents/NSC+Youth+Rulebook.docx.pdf" target="_blank">NSC Youth Rulebook</a></div>


                                <div className="NSCTitle" style={{ fontSize: "20px" }}>Season Schedule</div>

                                <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                                <div style={{width: "450px", margin: "5px"}}><img src={northeast_img} width="100%" height="auto"></img></div>
                                <div style={{width: "450px", margin: "5px"}}><img src={southeast_img} width="100%" height="auto"></img></div>
                                <div style={{width: "450px", margin: "5px"}}><img src={central_img} width="100%" height="auto"></img></div>
                                <div style={{width: "450px", margin: "5px"}}><img src={west_img} width="100%" height="auto"></img></div>
                                <div style={{width: "450px", margin: "5px"}}><img src={champ_img} width="100%" height="auto"></img></div>
                                </div>

                                <div className="NSCTitle" style={{ fontSize: "20px" }}>Register</div>
                                
                                
                                <div className="registerCard">
                                <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                    <div className="flex-row">
                                        <div style={{alignSelf: "center"}}><img src={sixfive_ninja_logo} width="150px"></img></div>
                                        <div className="cardTitle">
                                            <div className="gymName">605 Ninja</div>
                                            <div className="gymInfo">Sioux Falls, South Dakota</div>
                                            <div className="gymInfo">March 8 & 9, 2025</div>
                                        </div>
                                    </div>
                                    {/* <div className="gymInfo">Registration opens 1/21/25 at 7:00pm CST</div> */}
                                    <div><span className="gymInfo priceCell" style={{fontSize: "48px"}}>$100</span><a  className="registerButton" href="https://ninjamasterapp.com/events/5645-605-ninja-nsc-youth-qualifier" target="_blank">Register</a></div>
                                    {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5228-nsc4-qualifier-2-stamford-ninja-academy" target="_blank">Waitlist</a></div> */}
                                    {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                                </div>
                            </div>

                            <div className="registerCard">
                                <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                    <div className="flex-row">
                                        <div style={{alignSelf: "center"}}><img src={ninja_intensity_logo} width="150px"></img></div>
                                        <div className="cardTitle">
                                            <div className="gymName">Ninja Intensity</div>
                                            <div className="gymInfo">Castle Rock, Colorado</div>
                                            <div className="gymInfo">March 15 & 16, 2025</div>
                                        </div>
                                    </div>
                                    {/* <div className="gymInfo">Registration opens 1/23/25 at 6:00pm MST</div> */}
                                    <div><span className="gymInfo priceCell" style={{fontSize: "48px"}}>$100</span><a  className="registerButton" href="https://ninjamasterapp.com/events/5652-ninja-intensity-nsc-youth-qualifier" target="_blank">Register</a></div>
                                    {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5228-nsc4-qualifier-2-stamford-ninja-academy" target="_blank">Waitlist</a></div> */}
                                    {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                                </div>
                            </div>

                            <div className="registerCard">
                                <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                    <div className="flex-row">
                                        <div style={{alignSelf: "center"}}><img src="https://nsn-assets.s3.us-east-2.amazonaws.com/images/gym_logos/2018-ONA-alt_logo.jpg" width="150px"></img></div>
                                        <div className="cardTitle">
                                            <div className="gymName">Obstascle Ninja Academy</div>
                                            <div className="gymInfo">Orlando, Florida</div>
                                            <div className="gymInfo">March 15 & 16, 2025</div>
                                        </div>
                                    </div>
                                    <div className="gymInfo">Registration opens 1/26/25 at 8:00pm EST</div>
                                    <div><span className="gymInfo priceCell" style={{fontSize: "48px"}}>$100</span><a  className="registerButton" href="https://ninjamasterapp.com/events/5663-obstacle-ninja-academy-nsc-youth-qualifier" target="_blank">Register</a></div>
                                    {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5228-nsc4-qualifier-2-stamford-ninja-academy" target="_blank">Waitlist</a></div> */}
                                    {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                                </div>
                            </div>


                    {/* <div className="NSCTitle" style={{ fontSize: "20px" }}>Format</div>
                    <p>The NSC Youth Championship will be a 2 day event, all athletes will compete on two courses on day 1 the Hybrid course and the Speed Course. The top 10 boys and top 10 girls from each age division will advance to compete in the finals on Sunday where they will run all 3 courses, the Hybrid and Speed again, and then an added Burnout course. Run order will be determined by placement during the day 1 semi-finals round and the athletes will compete in the same run order across all 3 courses in the finals.</p>

                    <div className="NSCTitle" style={{ fontSize: "20px" }}>Run Order</div>
                    <p>The Run Order for Day 1 Semi-Finals will be determined by randomizing the athlete list for the course that they are starting on. For the athlete's run on their second course whether it is Speed or Hybird, the Athlete's run order will be the exact opposite of their run order for the first course. FOR EXAMPLE: If an athlete is randomly selected to run the Hybrid 1st overall, they will run the Speed last overall.
                        Run order for the Finals Round will be determined in reverse order of how the athlete's placed in the Semi-Finals round where the top athlete will run last etc.
                    </p>

                    <div className="NSCTitle" style={{ fontSize: "20px" }}>Retry System</div>

                    <p>NSC competitions use a rule set called the Retry System for the Open, Hybrid, and Speed rounds. Athletes are given 2 retries on every obstacle on the course and athletes must complete the obstacle to advance. Each time an athlete fails an obstacle they are given a “fail” which will affect their score. If an obstacle is failed 3 times the athlete’s run is over. When an athlete fails an obstacle they must return to the designated start platform for the obstacle and must wait a designated Reset Time and will receive the go-ahead from the judge when the reset time is over. Reset time is different for every course and is decided by the time it takes to reset the longest obstacle on the course.</p>

                    <div className="NSCTitle" style={{ fontSize: "20px" }}>Courses and Scoring</div>

                    <p>Hybrid Course: The hybrid course is a longer course of 6-15 obstacles combining elements of technical skill, endurance, and speed, it is modeled after the traditional course style that Ninja was founded on. This course utilizes the retry system.</p>

                    <p>Placement</p>

                    <ol>
                        <li>Most obstacles cleared</li>
                        <li>Total number of fails</li>
                        <li>Time to complete the Last Obstacle Completed (LOC)</li>
                    </ol>

                    <p>Speed Course: The speed course is comprised of easier obstacles focused on speed and efficiency with average course times around 20-60 seconds. This course also uses the retry system.</p>

                    <p>Placement</p>

                    <ol>
                        <li>Most Obstacles Cleared</li>
                        <li>Time to complete LOC</li>
                        <li>*Fails are not counted against the athlete in the speed round</li>
                    </ol>

                    <p>Burnout Course: The burnout course focuses on upper body endurance, once the athlete’s feet leave the ground they do not stop until they either fail an obstacle or finish. This is the only course without the retry system and once there is a fail the run is over.</p>

                    <p>Placement</p>

                    <ol>
                        <li>Most Obstacles Cleared</li>
                        <li>Time to complete LOC</li>
                    </ol>

                    <div className="NSCTitle" style={{ fontSize: "20px" }}>Semi-Finals Placement</div>
                    <p>Placement for Semi Finals is the same as the finals round (See Below) with the only two exceptions:</p>
                    <ol>
                    <li>Athletes will only run 2 courses, Hybrid and Speed so points are only added across 2 courses</li>
                    <li>If there is a tie in the semi finals, the Hybrid Course placement will be used as the tiebreaker</li>
                    <li>The Top 10 boys and top 10 girls will advance to the finals round</li>
</ol>

<div className="NSCTitle" style={{ fontSize: "20px" }}>Finals Placement</div>
                    <ol>
                        <li>Points are added across all 3 courses, Hybrid, Speed, Burnout and the winner is determined by the athlete with the most points</li>
                        <li>Points are awarded to 1st place based on the number of athletes competing and then each subsequent place is awarded 1 less point, EXAMPLE for 10 athletes there are 10 points awarded for 1st place, 9 points for 2nd place, 8 points for 3rd place, etc.</li>
                    </ol>

                    <p>TIE-BREAKING</p>

                    <ol>
                        <li>If two athletes are tied with the same points after all three courses, only the athlete’s top two placements will be added together to determine the winner.</li>
                        <li>If the athlete’s top 2 placements still result in a tie, only the athlete’s top placement will be compared to determine the winner.</li>
                        <li>If the top placement still results in a tie, the tiebreaker will be determined by the athlete who placed higher in the Open Round.</li>
                    </ol> */}
                </div>


                <NSNFooter title='' link='' linkTitle=''></NSNFooter>
            </div>
        );
    }
}

export default YouthChampionshipPage;