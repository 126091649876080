import React, {Component} from 'react';
import awsconfig from './../aws-exports'; // if you are using Amplify CLI
import Amplify, { API } from 'aws-amplify';
import {getUser} from '../api/aws/amplify_api';
import { connect } from 'react-redux';
import { verifyUser} from '../functions/auth_functions';
import {setUserToReduxState, logoutUserFromReduxState} from '../actions/auth_actions';
// import {CustomSignUp} from '../components/sign_up';
import Form from '../functions/authForm';
import NSNHeader from "../components/header";
// import '../style/clock_output.css';
import BottomLiveLeaderboard from "../components/bottomLiveLeaderboard";
import queryString from 'query-string';
import loadingImg from '../static/NSC-Blue-PNG-Outline.png';

Amplify.configure(awsconfig);

class BottomLiveLBPage extends Component {
    constructor(props) {
        super(props);
    this.state = {
        ws: null,
        dataFromServer: '',
        delay: 0
    };
}

componentDidMount() {
  console.log(this);
  var queryObj = queryString.parse(this.props.location.search);
    var compId;
    var delay = queryObj.delay || 0;
    if (queryObj.compId) {
      this.setState({compId: queryObj.compId, delay: delay})
  }
}
    render() {
      if(this.state.compId == ""){
        return (<div className="mainDiv" style={{height: "100vh"}}>
            <NSNHeader title='' link='' linkTitle=''></NSNHeader>
            <div className="flex-container-row" style={{alignItems: "center", height: "80%"}}>
                <div className="pulseImg">
                <img src={loadingImg} width="200px" height="auto" />
                </div>
                </div>
                </div>
        )
    }else{
      return (
          <div className="">
            <NSNHeader title='NSN Scoreboard' link='' linkTitle=''></NSNHeader>
            <div className="pageContainer" style={{margin: "0px"}}>

            <div className="clockContain">
              <div className="clock"><BottomLiveLeaderboard compId={this.state.compId} delay={this.state.delay}></BottomLiveLeaderboard></div>
            </div>

            </div>
          </div>
      );
    }
    }
  }

export default BottomLiveLBPage;
